import { $, addAction, INIT } from '@situation/setdesign.util';

function setPageStatus($page) {
    const $selected = $page.find('.gchoice').removeClass('selected').filter((k, el) => $(el).find('.gfield-choice-input')[0].checked).addClass('selected');
    if ($selected.length) {
        $page.trigger('enable-next');
    }
}

function preparePage(formID, pageID) {
    $('.gform_wrapper').removeClass('loading');
    $(`#gform_page_${formID}_${pageID}`).each((i, page) => {
        const $page = $(page);
        $page.find('.gchoice').each((j, choice) => {
            const $choice = $(choice);
            $choice.on('click', (e) => {
                const $selected = $(e.currentTarget);
                if (e.target === e.currentTarget) {
                    $selected.find('.gfield-choice-input').click();
                }
            });
            $choice.find('.gfield-choice-input').on('change', () => {
                setPageStatus($page);
            });
        });
        const $next = $page.find('.gform_next_button, .gform_button[type="submit"]');
        $next.attr('disabled', 'disabled');
        $page.on('enable-next', () => {
            $next.removeAttr('disabled');
        });
        setPageStatus($page);
    });
}

$(document).on('gform_page_loaded', (event, formID, currentPage) => {
    preparePage(formID, currentPage);
});
addAction(INIT, () => {
    preparePage(1, 1);
});
$(document).on('click', '.gform_next_button, .gform_button[type="submit"]', () => {
    $('.gform_wrapper').addClass('loading');
});
